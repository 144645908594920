.defaultSelect {
    min-width: 10rem !important;
}

.gradientFont {
    background: -webkit-linear-gradient(45deg, #f3b51c, #de9407);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: max-content;
}

.centeredThrobber {
    flex-grow: 1;
    height: 100%;
}

.userCard {
    @include grdatientBorder;
    height: fit-content;
    padding: 0.61rem;
    width: fit-content;
}

.bg-red {
    background-color: #ffdabc;
}

.pieChart { 
    width: 100%;
    margin-top: 1rem;
    overflow: hidden;
    height: 115px;

    &.l {
        height: 200px;
    }

    @media (min-width: 415px) {
        height: 140px;
    }

    @media (min-width: 530px) {
        height: 250px !important;
    }

    @media (min-width: 639px) {
        height: 350px !important;
    }

    @media (min-width: 991px) and (max-width: 1240px) {
        height: 250px !important;
    }
}

.messageToUser {
    max-width: 300px;
    word-break: break-all;
}

.ant-menu-inline-collapsed .menuDot .ant-badge-dot {
    top: 28%;
}