.vh100 {
    height: 100vh;
}

.w100 {
    width: 100%;
}

.tac {
    text-align: center;
}

.logo {
    width: 50px;
    height: 50px;
    background-image: url('../../public/logo.png');
    background-size: contain;
    background-repeat: no-repeat;

    &.large {
        width: 150px;
        height: 150px;
    }
}